import { isRef, ref, watchEffect } from 'vue'
import type { Ref, MaybeRefOrGetter } from 'vue'
import type { Composition } from '~/types/composable'

import useCustomer from '@/composables/useCustomer'

import { createSharedComposable } from '~/composables/utils/createSharedComposable'

const VARIANT_FIELDS = [
  'badge_text',
  'offer_price',
  'is_available',
  'sub_and_save_price_adjustment',
  'has_vip_special_pricing_discount',
].join()

interface subAndSavePriceAdjustment {
  adjusted_offer_price: number
}
interface UseVariantDetails extends Composition {
  [key: string]:
    | Ref<{
        badgeText: string
        price: number
        isAvailable: Boolean
        savePrice: object
        hasVipSpecialPricingDiscount: Boolean
      }>
    | MaybeRefOrGetter
}

const useVariantDetails = () => {
  const variantDetails: UseVariantDetails = {}
  const { id: customerId } = useCustomer()

  /**
   * Reset all variant details.
   * Next time a variant is requested, it will be re-fetched from the API.
   * This is useful when the customer's pricing or location may have changed.
   *
   */
  variantDetails.resetAllVariants = () => {
    for (const key in variantDetails) {
      if (isRef(variantDetails[key])) {
        variantDetails[key].value.reFetch = true
      }
    }
  }

  const variantIdsToFetch = ref('')

  /**
   * Watch for changes to the customerId and variantIdsToFetch.
   * When both are set, fetch the variant details from the API.
   */
  watchEffect(async () => {
    if (customerId.value && variantIdsToFetch.value) {
      const requestUrl = new URL(
        `/api/customer/${customerId.value}/variant-details/`,
        location.origin
      )
      requestUrl.searchParams.set('fields[variant]', VARIANT_FIELDS)

      // Clear the variantIdsToFetch.value before awaiting the fetch
      requestUrl.searchParams.set(
        'id__in',
        variantIdsToFetch.value.slice(0, -1)
      )
      variantIdsToFetch.value = ''

      const response = await fetch(requestUrl, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
      })

      if (
        response.ok &&
        response.headers?.get('content-type')?.includes('json')
      ) {
        const { data } = await response.json()
        for (const { id, attributes } of data) {
          if (id in variantDetails) {
            const {
              'badge-text': badgeText,
              'offer-price': price,
              'is-available': isAvailable,
              'sub-and-save-price-adjustment': subAndSavePriceAdjustment,
              'has-vip-special-pricing-discount': hasVipSpecialPricingDiscount,
            }: {
              'badge-text'?: string
              'offer-price': number
              'is-available': boolean
              'sub-and-save-price-adjustment': subAndSavePriceAdjustment
            } = attributes
            variantDetails[id].value = {
              badgeText,
              price: price / 100,
              isAvailable,
              savePrice:
                subAndSavePriceAdjustment?.adjusted_offer_price &&
                subAndSavePriceAdjustment.adjusted_offer_price / 100,
              hasVipSpecialPricingDiscount:
                hasVipSpecialPricingDiscount || false,
            }
          }
        }
      }
    }
  })

  return new Proxy(variantDetails, {
    get: (target, propertyKey, receiver) => {
      const keyName =
        typeof propertyKey === 'symbol' ? propertyKey.toString() : propertyKey

      if (!Reflect.has(target, propertyKey)) {
        Reflect.set(target, propertyKey, ref(undefined), receiver)
        variantIdsToFetch.value += `${keyName},`
      }
      const result = Reflect.get(target, propertyKey, receiver)
      if (result?.value?.reFetch) {
        variantIdsToFetch.value += `${keyName},`
        delete result.value.reFetch
      }
      return result
    },
    set: () => {
      throw new TypeError('VariantDetails is read only')
    },
  })
}

export default createSharedComposable(
  useVariantDetails
) as () => UseVariantDetails
