import { PLASTIC_BADGES } from '@/constants/badges'

/**
 * Find the Beyond Plastic badge corresponding to the tag
 */
export function findBeyondPlasticBadge(tags?: string[]) {
  if (!tags) {
    return null
  }

  for (const tag of tags) {
    if (tag && tag in PLASTIC_BADGES) {
      return {
        ...PLASTIC_BADGES[tag],
        name: tag,
      }
    }
  }
  return null
}
