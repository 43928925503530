/**
 * This regular expression will capture the `csrftoken` value from the document.cookie string.
 * If there are multiple cookies they can be separated by semicolons followed by a space. We use this to create our three capturing groups.
 * The expression finds the `csrftoken=` and uses the first capture group to assert that it is either the start of the string or after another cookie's value.
 * The second capturing group grabs the actual value of the cookie as long as it does not start with a semicolon which indicates no value.
 * The third capturing group asserts the cookie value ends with a semicolon or is at the end of the string.
 */
const tokenExpression = /(^|;) ?csrftoken=([^;]*)(;|$)/

const getCSRFToken = (): string => {
  const match = tokenExpression.exec(document.cookie)
  // Return the second capturing group of the expression
  return (match && match[2]) || ''
}

export default getCSRFToken
