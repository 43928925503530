import { ref, computed, watch, reactive } from 'vue'
import * as JSONAPI from 'jsonapi-typescript'
import { parseISO, format } from 'date-fns'

import useCustomer from '~/composables/useCustomer'
import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import { refProxy } from '~/composables/utils/refProxy'
import getCSRFToken from '~/composables/utils/getCSRFToken'

import { FALLBACK_PRODUCT_IMAGE } from '~/constants/images'
import { BACK_SOON_BADGE_TEXT } from '~/constants/productBadges'

import { resolveRef } from '~/utils/resolveRef'
import { camelCase } from '~/utils/string'

import type {
  Cart,
  CartItem,
  CartItemMutation,
  CartMutation,
  UseCart,
  CartItemAttributes,
  CartItemProductAttributes,
  CartItemVariantAttributes,
} from '~/types/cart'

// Any field changes should reflect in the cart ts definitions.
const SHIPMENT_FIELDS = [
  'amount_below_free_shipping',
  'amount_below_minimum',
  'amount_below_ship_now_minimum',
  'care_commitment_fee',
  'charge_price',
  'charge_tax_price',
  'displayed_credit',
  'displayed_gift_set_value',
  'displayed_subtotal_price',
  'displayed_total_list_price',
  'final_charge_price',
  'first_move_date',
  'free_shipping_minimum',
  'has_care_commitment_fee',
  'item_count',
  'items',
  'meets_minimum',
  'minimum_price',
  'minimum',
  'offer_price',
  'order_number',
  'price_excluding_gift_set',
  'price',
  'savings_celebration_items',
  'savings_celebration_total',
  'seen_at',
  'shipment_date',
  'shipping_price',
  'shipping_regular',
  'should_display_autoship_disclaimer_on_checkout',
  'supply_chain_fee',
  'tipper_offer_opt_out_all',
  'tipper_offer_source',
  'tipper_offer',
  'tipper_price',
  'value_discount',
]
const ITEM_FIELDS = [
  'adjusted_price_qty',
  'adjusted_price',
  'is_available',
  'lock_variant',
  'offer_price',
  'product_max_qty',
  'quantity',
  'source',
  'variant_sku',
  'variant',
  // Even though we don't need the shipment field, it is required by Django ensure_shipmentitem.
  'shipment',
]
const ITEM_INCLUDES = ['variant.product']
const PRODUCT_FIELDS = [
  'is_subscribable',
  'name',
  'variants',
  'reship_frequency',
]
const VARIANT_FIELDS = [
  'customer_facing_display_name',
  'images',
  'list_price',
  'name',
  'product',
  'relative_url',
  'sub_and_save_price_adjustment',
  'unit_display_name',
  'volume',
]

// These fields will be converted to date objects.
const DATE_FIELDS = ['firstMoveDate', 'shipmentDate']

/**
 * Indicates a request responded with a failure.
 */
export class CartRequestFailed extends Error {
  name: 'CartRequestFailed'
  response: Response
  constructor(message: string, response: Response) {
    super()
    this.name = 'CartRequestFailed'
    this.message = message
    this.response = response
  }
}

/**
 * Validates and converts a shipment-item into a cart item representation.
 *
 */
const buildCartItem = (
  { id = '', attributes = {}, relationships = {} }: JSONAPI.ResourceObject,
  included: JSONAPI.Included = []
): CartItem => {
  // Cart item variantId comes from the relationship data.
  const variantId = relationships.variant?.data?.id
  // Find the included variant attributes and relationships.
  const {
    attributes: variantAttributes = {},
    relationships: variantRelationships,
  } =
    included.find((item) => item.type === 'variant' && item.id === variantId) ||
    {}
  // Cart item productId comes from the included variant relationship data.
  const productId = variantRelationships?.product?.data?.id
  // Find the included product attributes and relationships.
  const {
    attributes: productAttributes = {},
    relationships: productRelationships,
  } =
    included.find((item) => item.type === 'product' && item.id === productId) ||
    {}
  // Cart item variantIds include all variants associated with the product.
  const variantIds = productRelationships?.variants?.data?.map(
    ({ id }: JSONAPI.ResourceIdentifierObject) => id
  ) || [variantId]

  // Extract cart item properties from various sources.
  const {
    'adjusted-price-qty': adjustedPriceQty,
    'adjusted-price': adjustedPrice,
    'is-available': isAvailable = false,
    'lock-variant': lockVariant = false,
    'offer-price': offerPrice,
    'product-max-qty': quantityMax,
    'variant-sku': variantSku,
    quantity,
    source = 0,
  } = attributes as CartItemAttributes
  const {
    'is-subscribable': isSubscribable = false,
    name: productName = '',
    'reship-frequency': reshipFrequency = null,
  } = productAttributes as CartItemProductAttributes
  const {
    'customer-facing-display-name': name = '',
    'list-price': listPrice,
    name: vName = '',
    'relative-url': productUrl,
    'sub-and-save-price-adjustment': subAndSavePriceAdjustment = {},
    'unit-display-name': unit,
    images = [],
    volume,
  } = variantAttributes as CartItemVariantAttributes
  const splitName = name.split(' - ')
  const variantName =
    typeof vName === 'string' && vName.toLowerCase() !== 'default'
      ? vName
      : null
  const adjustmentAmount =
    subAndSavePriceAdjustment?.adjustment_amount_as_percentage
  const futureAdjustmentAmount =
    subAndSavePriceAdjustment?.future_amount_as_percentage

  const getBadgeText = () => {
    // badgeText attribute calculation should be moved to backend.
    if (isAvailable === false) {
      return BACK_SOON_BADGE_TEXT
    }

    if (source === 185) {
      return 'VIP Gift'
    }

    return null
  }

  return {
    id,
    adjustedPrice,
    listPrice: listPrice && listPrice * quantity,
    offerPrice,
    subscriptionSavingsPercentage:
      adjustmentAmount && adjustmentAmount < 0
        ? `${Math.abs(adjustmentAmount)}%`
        : null,
    subscriptionFutureSavingsPercentage:
      futureAdjustmentAmount && futureAdjustmentAmount < 0
        ? `${Math.abs(futureAdjustmentAmount)}%`
        : null,
    quantity,
    // quantityFree attribute calculation should be moved to backend.
    quantityFree:
      adjustedPrice === 0 ? Math.min(adjustedPriceQty || 1, quantity) : 0,
    quantityMax,

    badgeText: getBadgeText(),
    brandName: splitName.length > 1 ? splitName[0] : null,
    image: images[0] || FALLBACK_PRODUCT_IMAGE,
    isAvailable,
    isSubscribable,
    lockVariant: Boolean(lockVariant),
    name,
    productId,
    productName,
    productUrl,
    reshipFrequency,
    secondaryText: [volume && volume > 0 && `${volume} ${unit}`, variantName]
      .filter(Boolean)
      .join(' - '),
    variantCount: variantIds.length,
    variantId,
    variantIds,
    variantName,
    variantSku,
    source,
  }
}

const buildHeaders = () =>
  new Headers({
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'X-CSRFToken': getCSRFToken(),
  })

const ignoreAbortError = (error: Error | unknown) => {
  if (error instanceof Error && error.name !== 'AbortError') {
    throw error
  }
}

/**
 * Validates and processes a JSON:API response.
 */
const getResponseData = async (
  response: Response,
  type = 'Cart'
): Promise<JSONAPI.SingleResourceDoc | JSONAPI.CollectionResourceDoc> => {
  const expectJSON = response?.headers?.get('content-type')?.includes('json')
  if (!(response?.ok && expectJSON)) {
    // If the failed response contains json attempt to include the error.
    if (expectJSON) {
      const { errors = {} } = await response.json()
      const error = Object.values(errors).flat().join(' ')
      throw new CartRequestFailed(`${type}: ${error}`, response)
    }

    throw new CartRequestFailed(type, response)
  }
  return await response.json()
}

const useCart = () => {
  const { shipmentId } = useCustomer()

  // Abort controller of the last cart get request.
  let updateCartAbortController: AbortController | null = null
  // Abort controller of the last cart items get request.
  let updateCartItemsAbortController: AbortController | null = null

  const pendingCartItemUpdate = ref(false)
  const isBusy = ref(true)

  const cart = refProxy({}) as UseCart

  cart.items = ref([])
  cart.itemCount = ref(0)
  cart.isCartSynced = computed<boolean>(() => {
    return Boolean(
      !pendingCartItemUpdate.value &&
        !isBusy.value &&
        cart.itemCount.value === cart.items.value.length
    )
  })

  /**
   * Converts cart values into a JSON string that the shipment API expects.
   */
  const buildShipmentBody = async (partial: CartMutation): Promise<string> => {
    const { tipperOfferId, shipmentDate, ...attributes } = partial

    const data = {
      id: (await resolveRef(shipmentId)).value,
      type: 'shipment',
      attributes: shipmentDate
        ? {
            ...attributes,
            shipmentDate: format(shipmentDate, 'yyyy-MM-dd'),
          }
        : attributes,
      relationships: {},
    }

    if ('tipperOfferId' in partial) {
      data.relationships = {
        tipperOffer: tipperOfferId && {
          data: {
            id: tipperOfferId,
            type: 'tipper-offer',
          },
        },
      }
    }

    return JSON.stringify({ data })
  }

  /**
   * Converts a cart item object into a JSON string that the shipment-item API expects.
   *
   */
  const buildShipmentItemBody = async ({
    id,
    variantId,
    ...attributes
  }: CartItemMutation): Promise<string> =>
    JSON.stringify({
      data: {
        id,
        type: 'shipment-item',
        attributes,
        relationships: {
          shipment: {
            data: {
              id: (await resolveRef(shipmentId)).value,
              type: 'shipment',
            },
          },
          variant: variantId && {
            data: {
              id: variantId,
              type: 'variant',
            },
          },
        },
      },
    })

  /**
   * Processes a single shipment item response from the API and applies the result to a corresponding cart item.
   *
   */
  const handleShipmentItemResponse = async (
    response: Response
  ): Promise<CartItem> => {
    const { data, included } = await getResponseData(response, 'Shipment Item')
    // Typescript doesn't know shipments items always return single results.
    if (Array.isArray(data)) {
      throw new TypeError('Expected single item response')
    }

    const cartItem = buildCartItem(data, included)

    const existingIndex = cart.items.value.findIndex(
      ({ id }) => cartItem.id === id
    )
    if (existingIndex < 0) {
      // Add new cart item to the bottom of the list.
      cart.items.value.push(reactive(cartItem))
    } else {
      // Update existing reactive cart item.
      Object.assign(cart.items.value[existingIndex], cartItem)
    }

    await cart.updateCart()
    return cartItem
  }

  /**
   * Update the properties of the cart from the API.
   *
   */
  cart.updateCart = async (partial) => {
    // Abort any get requests in progress.
    if (updateCartAbortController) {
      updateCartAbortController.abort()
      updateCartAbortController = null
    }

    const body =
      partial && Object.keys(partial).length
        ? await buildShipmentBody(partial)
        : null
    const method = body ? 'PATCH' : 'GET'

    const headers = new Headers({
      Accept: 'application/vnd.api+json',
    })

    if (method === 'GET') {
      if (!shipmentId.value) {
        return
      }
      updateCartAbortController = new AbortController()
    } else {
      isBusy.value = true
      headers.set('Content-Type', 'application/vnd.api+json')
      headers.set('X-CSRFToken', getCSRFToken())
    }

    try {
      const requestUrl = new URL(
        `/api/shipment/${shipmentId.value}/`,
        location.origin
      )
      requestUrl.searchParams.set(
        'fields[shipment]',
        SHIPMENT_FIELDS.toString()
      )
      const response = await fetch(requestUrl, {
        headers,
        method,
        signal: updateCartAbortController?.signal,
        body,
      })

      const {
        data: { id, attributes = {}, relationships = {} },
      } = (await getResponseData(
        response,
        'Shipment'
      )) as JSONAPI.SingleResourceDoc

      cart.id.value = id
      cart.itemCount.value = (relationships.items?.data?.length ||
        0) as Cart['itemCount']
      cart.tipperOfferId.value = relationships['tipper-offer']?.data
        ?.id as Cart['tipperOfferId']

      // Update all cart refs using shipment attributes.
      for (const [key, value] of Object.entries(attributes)) {
        const cartKey = camelCase(key) as keyof UseCart
        const maybeRefOrGetter = cart[cartKey] as Ref
        if (!isRef(maybeRefOrGetter)) continue
        if (DATE_FIELDS.includes(cartKey) && typeof value === 'string') {
          maybeRefOrGetter.value = parseISO(value)
        } else {
          maybeRefOrGetter.value = value
        }
      }
    } catch (error) {
      ignoreAbortError(error)
    } finally {
      updateCartAbortController = null
      isBusy.value = false
    }
  }

  /**
   * Adds a new item to the cart
   */
  cart.createCartItem = async (partialItem) => {
    const { variantId } = partialItem
    if (!variantId) {
      throw new Error('Variant ID required')
    }

    updateCartItemsAbortController?.abort()
    pendingCartItemUpdate.value = true

    try {
      const requestUrl = new URL('/api/shipment-item/', location.origin)
      requestUrl.searchParams.set('include', ITEM_INCLUDES.toString())
      requestUrl.searchParams.set(
        'fields[shipment-item]',
        ITEM_FIELDS.toString()
      )
      requestUrl.searchParams.set('fields[product]', PRODUCT_FIELDS.toString())
      requestUrl.searchParams.set('fields[variant]', VARIANT_FIELDS.toString())
      const response = await fetch(requestUrl, {
        headers: buildHeaders(),
        method: 'POST',
        body: await buildShipmentItemBody(partialItem),
      })
      return handleShipmentItemResponse(response)
    } finally {
      pendingCartItemUpdate.value = false
    }
  }

  /**
   * Update the properties of a cart item.
   * Not all properties are editable. Changes to read-only properties will be ignored.
   *
   */
  cart.updateCartItem = async (partialItem) => {
    const method = Object.keys(partialItem).length === 1 ? 'GET' : 'PATCH'

    updateCartItemsAbortController?.abort()
    pendingCartItemUpdate.value = true

    try {
      const requestUrl = new URL(
        `/api/shipment-item/${partialItem.id}/`,
        location.origin
      )
      requestUrl.searchParams.set('include', ITEM_INCLUDES.toString())
      requestUrl.searchParams.set(
        'fields[shipment-item]',
        ITEM_FIELDS.toString()
      )
      requestUrl.searchParams.set('fields[product]', PRODUCT_FIELDS.toString())
      requestUrl.searchParams.set('fields[variant]', VARIANT_FIELDS.toString())
      const response = await fetch(requestUrl, {
        headers: buildHeaders(),
        method,
        body:
          method === 'PATCH' ? await buildShipmentItemBody(partialItem) : null,
      })

      return await handleShipmentItemResponse(response)
    } finally {
      pendingCartItemUpdate.value = false
    }
  }

  /**
   * Refresh all items in the cart from the API.
   *
   */
  cart.updateCartItems = async () => {
    if (!shipmentId.value) return
    // Abort and reset the abort controller
    updateCartItemsAbortController?.abort()
    updateCartItemsAbortController = new AbortController()

    try {
      const requestUrl = new URL(
        `/api/shipment/${shipmentId.value}/items/`,
        location.origin
      )
      requestUrl.searchParams.set('include', ITEM_INCLUDES.toString())
      requestUrl.searchParams.set(
        'fields[shipment-item]',
        ITEM_FIELDS.toString()
      )
      requestUrl.searchParams.set('fields[product]', PRODUCT_FIELDS.toString())
      requestUrl.searchParams.set('fields[variant]', VARIANT_FIELDS.toString())
      const response = await fetch(requestUrl, {
        headers: new Headers({
          Accept: 'application/vnd.api+json',
        }),
        method: 'GET',
        signal: updateCartItemsAbortController?.signal,
      })

      const { data, included = [] } = await getResponseData(
        response,
        'Cart Items'
      )
      cart.items.value = Array.isArray(data)
        ? data.map((item) => reactive(buildCartItem(item, included)))
        : []
    } catch (error) {
      ignoreAbortError(error)
    } finally {
      // If there is a pendingCartItemUpdate the watcher
      // will take care of clearing the abort controller
      if (!pendingCartItemUpdate.value) {
        updateCartItemsAbortController = null
      }
    }
  }

  /**
   * Remove an item in the cart.
   */
  cart.removeCartItem = async (partialItem) => {
    if (!partialItem.id) {
      throw new Error('ID required')
    }

    updateCartItemsAbortController?.abort()
    pendingCartItemUpdate.value = true

    try {
      const response = await fetch(`/api/shipment-item/${partialItem.id}/`, {
        headers: new Headers({
          'X-CSRFToken': getCSRFToken(),
        }),
        method: 'DELETE',
      })

      if (!response.ok && response.status !== 404) {
        throw new CartRequestFailed('Shipment Item', response)
      }

      const cartItem = cart.items.value.find(({ id }) => id === partialItem.id)
      if (cartItem) {
        cart.items.value = cart.items.value.filter(
          ({ id }) => id !== partialItem.id
        )
      }

      cart.updateCart()
      return { ...cartItem, ...partialItem }
    } finally {
      pendingCartItemUpdate.value = false
    }
  }

  /**
   * Update cart if shipmentId changes such as when the customer loads.
   */
  watch(
    shipmentId,
    () => {
      cart.updateCart()
      cart.updateCartItems()
    },
    {
      immediate: true,
    }
  )

  /**
   * Watch for pending cart item updates.
   */
  watch(pendingCartItemUpdate, (isPendingUpdate) => {
    // Check if a cart item update caused updateCartItems request to be aborted.
    // Now the item update is complete, the full update can be run.
    if (!isPendingUpdate && updateCartItemsAbortController?.signal.aborted) {
      updateCartItemsAbortController = null
      cart.updateCartItems()
    }
  })

  return cart
}

/**
 * Builds and returns the shared cart composable.
 *
 * @returns {CartComposable} The CartComposable shared instance.
 */
export default createSharedComposable(useCart) as () => UseCart
