export const BP_IMAGE_URL_BASE =
  'https://images.grove.co/upload/v1696606762/global/Logos/BeyondPlastic/'

const BP_100_PLASTIC_IMG_NAME = 'beyond-plastic-100-plastic-free'
const BP_95_PLASTIC_IMG_NAME = 'beyond-plastic-95-plastic-free'
const BP_NO_SINGLE_USE_IMG_NAME = 'beyond-plastic-no-single-use-plastic'
const BP_IMG_NAME = 'Beyond-Plastic-Logo'

export const BEYOND_PLASTIC_100_IMG = `${BP_IMAGE_URL_BASE}${BP_100_PLASTIC_IMG_NAME}.svg`
export const BEYOND_PLASTIC_95_IMG = `${BP_IMAGE_URL_BASE}${BP_95_PLASTIC_IMG_NAME}.svg`
const BEYOND_PLASTIC_NO_SINGLE_USE_IMG = `${BP_IMAGE_URL_BASE}${BP_NO_SINGLE_USE_IMG_NAME}.svg`
const BEYOND_PLASTIC_IMG = `${BP_IMAGE_URL_BASE}${BP_IMG_NAME}.svg`

const BP_100_PLASTIC_DESCRIPTION =
  'This product is 100% plastic-free. There are no plastic lacquers, liners, or coatings.'

const BP_95_PLASTIC_DESCRIPTION =
  'This product contains no more than 5% plastic by total weight. This category includes products that may look 100% plastic-free, but have a plastic liner, lacquer, or coating that generally does not affect recyclability.'

const BP_NO_SINGLE_USE_DESCRIPTION =
  'This product contains durable, reusable plastic, defined by the EPA as lasting 3 years or more. There is no single-use plastic.'

const BP_DESCRIPTION =
  'These items are 100% plastic-free, 95%+ plastic-free, or have no single-use plastic.'

type PlasticBadge = {
  description: string
  image: string
  imageTall: string
  name?: string
}

export const PLASTIC_BADGES: {
  [key: string]: PlasticBadge
} = {
  '100% Plastic Free': {
    description: BP_100_PLASTIC_DESCRIPTION,
    image: BEYOND_PLASTIC_100_IMG.replace('.svg', '-1x1.svg'),
    imageTall: BEYOND_PLASTIC_100_IMG,
  },
  '95%+ Plastic Free': {
    description: BP_95_PLASTIC_DESCRIPTION,
    image: BEYOND_PLASTIC_95_IMG.replace('.svg', '-1x1.svg'),
    imageTall: BEYOND_PLASTIC_95_IMG,
  },
  'No Single Use Plastic': {
    description: BP_NO_SINGLE_USE_DESCRIPTION,
    image: BEYOND_PLASTIC_NO_SINGLE_USE_IMG.replace('.svg', '-1x1.svg'),
    imageTall: BEYOND_PLASTIC_NO_SINGLE_USE_IMG,
  },
  'Beyond Plastic': {
    description: BP_DESCRIPTION,
    image: BEYOND_PLASTIC_IMG,
    imageTall: BEYOND_PLASTIC_IMG,
  },
}
