/**
 * Note: FALLBACK_PRODUCT_IMAGE depends on transformImage(), which depends on IMAGE_HOST.
 * To avoid a circular dependency, we declare IMAGE_HOST in the transformImage() module
 * and re-export in lieu of defining here in this module.
 */
export { IMAGE_HOST } from '~/utils/transformImage'

export const DEFAULT_TRANSFORMATIONS = 'f_auto,fl_progressive,q_auto'
export const PRODUCT_TILE_TRANSFORMATIONS = `${DEFAULT_TRANSFORMATIONS},h_368,w_auto,dpr_auto,ar_1:1,c_pad,b_white`
export const FALLBACK_PRODUCT_IMAGE = transformImage(
  'https://images.grove.co/upload/v1588037272/global/Line%20Illustrations/no-image-available.png',
  `${PRODUCT_TILE_TRANSFORMATIONS},bo_20px_solid_white`
)
